 

function KeyCard() {
  return (
   <div className='key-container-main'>
    <div className="container-1-key">
    <i class="fa-solid fa-user key-card-icon"></i>
    <h1 className='key-head'>Master Chefs</h1>
    <p className='key-para'>Chef Ravindra believes in nourishing both the body and soul with flavorful vegan and vegetarian dishes. His journey began with a passion for creating meals that are good for both people and the planet. </p>
    </div>
    <div className="container-1-key">
    <i class="fa-solid fa-utensils key-card-icon"></i>
    <h1 className='key-head'>Quality Food</h1>
    <p className='key-para'>Our mission is to offer fresh, wholesome vegan food that nourishes the soul and inspires a healthier lifestyle. We believe that food is a reflection of love and care, crafted with sustainability, ethical sourcing, and a dedication to fostering community. </p>
    </div>
    <div className="container-1-key">
    <i class="fa-solid fa-cart-shopping key-card-icon"></i>
    <h1 className='key-head'>Online Order</h1>
    <p className='key-para'>Due to our early start, we are currently offering online food delivery only. You can place your order through thuisbezorgd and UberEats for pickup or delivery. Stay tuned, as we will soon be opening for dining at the restaurant! Want to nourish your mind, body, and soul with great flavor? place your order online today !  </p>
    </div>
    <div className="container-1-key">
    {/* <i class="fa-solid fa-headset key-card-icon"></i> */}
    {/* <i class="fa-solid fa-carrot key-card-icon"></i> */}
    <i class="fa-solid fa-leaf key-card-icon"></i>
    <h1 className='key-head'>Why Vegetarian?</h1>
    <p className='key-para'>A vegetarian diet is rich in vitamins, antioxidants, and fiber while reducing environmental impact by cutting down on the carbon footprint. </p>
    </div>
   </div>
  );
}

export default KeyCard;

