import React from 'react';
import Navbar from '../Navbar'; 
import dishImage from '../../src/assets/staticImages/00bfb507fb37fa175fde42be0c64eb0a-removebg-preview.png'
import KeyCard from '../KeyCards';
import About from '../About';
import Menu from '../Menu';
import Contact from '../Contact';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import DishTable from '../AdminDashboard/DishTable';
import Footer from '../Footer';

function Home() {
  
  return (
    <div className='home-main-container' id="home">
        <Navbar/>
       <div className='home-page-beneath-nav-container'>
        <div className='home-left-container'>
    <h1 className='txt-head-home black-heading'>Enjoy Your</h1>
    <h1 className='txt-head-home black-heading'>Delicious Meal</h1>
    <p className='home-text-content'>Welcome to our first restaurant Ishaan Pure Veg, where we celebrate the art of vegen & vegetarian cuisine! With farm-fresh ingredients and a creative blend of flavors, we invite you to experience delicious, wholesome meals crafted with love and care. Nowhere else can you find this unique flavor experience!
   <br/> Many thanks.</p>
      <AnchorLink href='#contact' style={{textDecoration:'none',color:'#000'}}>
    <button class='contact-now-btn' style={{color:'#fff'}}>
        Contact Now
        </button>
        </AnchorLink>
        </div>
        <div className='home-right-container'>
        <img src={dishImage} className='home-image'/>
        </div>
       </div>
       <KeyCard /> 
       <About/>
       <Menu/>
       <Contact/>
       <Footer/>
    </div> 
  );
}

export default Home;
